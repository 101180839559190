import { FC, PropsWithChildren } from 'react';
import styles from './ScrollCardsList.module.scss';
interface IScrollCardsListProps {
  extraClassName?: string;
}

const ScrollCardsList: FC<PropsWithChildren<IScrollCardsListProps>> = ({ children, extraClassName }) => {
  const getListClassNames = () => (extraClassName ? `${styles.scrollXWrapper} ${styles[`${extraClassName}`]}` : `${styles.scrollXWrapper}`);

  return (
    <div className={getListClassNames()}>
      <div className={styles.scrollCards} data-scrollbar='styles'>
        <div className={styles.scrollCards__wrap}>{children}</div>
      </div>
    </div>
  );
};

export default ScrollCardsList;
