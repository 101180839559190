import { FC } from 'react';
import Link from 'next/link';
import styles from './ScrollCardItem.module.scss';
import { IBook, IAuthor } from '@/interfaces/home.interface';
import { getAuthorFullName, getTruncatedTxt, hideImgOnError } from '@/helpers/helpers';
import { DEV_ABSOLUTE_URL } from '@/helpers/variables';

interface IScrollCardItemProps {
  book: IBook | any;
  author: IAuthor | any;
  rating: number;
  id: number;
}

const ScrollCardItem: FC<IScrollCardItemProps> = ({ id, rating, author, book }) => {
  const { title, description } = book;
  const { first_name, middle_name, last_name } = author;
  const authorFullName = getAuthorFullName(first_name, middle_name, last_name);
  const bookRating = Number(rating);

  const bookFullAlias = `/book/${id}`;
  const authorFullAlias = `/library/author/${author.id}`;

  return (
    <div className={styles.scrollCard}>
      <div className={styles.scrollCard__info}>
        <Link prefetch={false} className={styles.scrollCard__imgWrap} href={bookFullAlias}>
          <img loading="lazy" className={styles.scrollCard__img} src={`${DEV_ABSOLUTE_URL}/covers_120/${book.external_id}.jpg`} width="104" height="148" alt={title} onError={hideImgOnError} />
          {/* {% include "blocks/draft_sticker.twig" %} */}
        </Link>
        <div className={styles.scrollCard__desc}>
          <Link prefetch={false} className={styles.scrollCard__title} href={bookFullAlias}>
            {getTruncatedTxt(title, 58)}
          </Link>
          <Link prefetch={false} className={styles.scrollCard__author} href={authorFullAlias}>
            {authorFullName}
          </Link>
          <div className={styles.scrollCard__rating}>
            <span className={styles.scrollCard__ratingNumber}>{bookRating > 0 ? bookRating : 0}</span>
          </div>
        </div>
      </div>
      <div className={styles.scrollCard__text}>{getTruncatedTxt(description, 100)}</div>
    </div>
  );
};

export default ScrollCardItem;
