import { FC, useState } from 'react';
import styles from './CardsControls.module.scss';

interface ICardsControlsProps {
  extraClassName?: string;
  setIsCardsColumn: (isCardsColumn: boolean) => void;
}

const CardsControls: FC<ICardsControlsProps> = ({ extraClassName, setIsCardsColumn }) => {
  const [isButtonRow, setIsButtonRow] = useState(true);
  const [isButtonColumn, setIsButtonColumn] = useState(false);

  const getControlsClassNames = () => (extraClassName ? `${styles.cardsControls} ${styles[`${extraClassName}`]}` : styles.cardsControls);

  const rowButtonHandler = () => {
    setIsButtonRow(true);
    setIsButtonColumn(false);
    setIsCardsColumn(false);
  };

  const columnButtonHandler = () => {
    setIsButtonColumn(true);
    setIsButtonRow(false);
    setIsCardsColumn(true);
  };

  return (
    <div className={getControlsClassNames()}>
      <div className={styles.cardsControls__buttons}>
        <div onClick={rowButtonHandler} className={isButtonRow ? `${styles.cardsControls__button} ${styles.cardsControls__button_rows} ${styles.cardsControls__button_active}` : `${styles.cardsControls__button} ${styles.cardsControls__button_rows}`}></div>
        <div onClick={columnButtonHandler} className={isButtonColumn ? `${styles.cardsControls__button} ${styles.cardsControls__button_tiles} ${styles.cardsControls__button_active}` : `${styles.cardsControls__button} ${styles.cardsControls__button_tiles}`}></div>
      </div>
    </div>
  );
};

export default CardsControls;
