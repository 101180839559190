import { FC } from 'react';
import styles from './SectionTitle.module.scss';

interface ISectionTitleProps {
  name: string;
  extraClassName?: string;
}

const SectionTitle: FC<ISectionTitleProps> = ({ name, extraClassName }) => {
  const getHeadingClassNames = () => (extraClassName ? `${styles.mainTitle} ${styles[`${extraClassName}`]}` : styles.mainTitle);

  return <h1 className={getHeadingClassNames()}>{name}</h1>;
};

export default SectionTitle;
