import { FC, useState } from 'react';
import SectionTitle from '../section-title/SectionTitle';
import CardsControls from '../cards-controls/CardsControls';
import styles from './SectionTitleMenu.module.scss';

interface ISectionTitleProps {
  name: string;
}

const SectionTitleMenu: FC<ISectionTitleProps> = ({ name }) => {
  const [isCardsColumn, setIsCardsColumn] = useState(false);

  return (
    <div className={styles.cardsTitleWrap} data-btn-cards={isCardsColumn ? 'tiles' : 'rows'}>
      <SectionTitle name={name} extraClassName={'mainTitle_cards_btns'} />
      <CardsControls setIsCardsColumn={setIsCardsColumn} extraClassName='cardsControls_mb' />
    </div>
  );
};

export default SectionTitleMenu;
